import { faArrowDown, faArrowRight, faBuilding, faComment, faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import Input from '../../../components/Input/input';
import { API_SubmitContact } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from './Contact.module.scss';
import ReCAPTCHA from "react-google-recaptcha";
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Banner from '../../../components/Banner/Banner';
import { UserContext } from '../../../Context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import LogoIconImg from '../../../assets/IMG/PrimeHR Logo-var(--BackgroundColor).png';

type ContactForm = {
  Forename: string;
  Surname: string;
  Company: string;
  Email: string;
  Phone: string
  Message: string;
  Captcha: string;
  FullName: string;
}

interface Props {
  hideBanner: boolean;
}

const Contact: React.FC<Props> = ({ hideBanner }) => {
  const { User } = useContext(UserContext);
  const [contact, setContact] = useState<ContactForm>({
    Forename: "",
    Surname: "",
    Company: "",
    Email: "",
    Phone: "",
    Message: "",
    Captcha: "",
    FullName: ""
  } as ContactForm);
  const [contactMessage, setContactMessage] = useState<string>("");
  const [contactSuccess, setContactSuccess] = useState<boolean>(false);

  const QuickFill = () => {
    if (User.ID) {
      setContact({
        Forename: User.Forename,
        Surname: User.Surname,
        Company: User.Company,
        Email: User.Email,
        Phone: User.Phone,
        Message: "",
        Captcha: "",
        FullName: ""
      } as ContactForm)
    }
  };

  const Submit = async (e: any) => {
    e.preventDefault();
    let form: ContactForm = JSON.parse(JSON.stringify(contact));
    form.FullName = contact.Forename + " " + contact.Surname;
    let success: boolean = await Fetch(`${API_SubmitContact}`, form);
    let message: string = "";
    if (success) {
      message = "Your message has been successfully sent!";
      setContact({
        Forename: "",
        Surname: "",
        Company: "",
        Email: "",
        Phone: "",
        Message: "",
        Captcha: "",
        FullName: ""
      } as ContactForm);
    } else
      message = "There was a problem sending your message";
    setContactMessage(message);
    setContactSuccess(success);
  }

  return (
    <>
      {hideBanner ? <></> : <>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>PrimeHR :: Contact Us</title><meta charSet='utf-8' />
        </Helmet>

        <Banner />

        <Breadcrumb Trail={[
          { To: "", Text: "Contact" }
        ]} />
      </>}

      <div className={styles.Contact}>
        <div className={styles.ContactInner}>
          <h1>Contact Us</h1>
          <p>Get in touch with our knowledgeable team today to find out how a partnership with PrimeHR can benefit your business and team.</p>

          <div className={styles.Options}>
            <div className={styles.OptionsItem}>
              <i>
                <FontAwesomeIcon icon={faPhone} />
              </i>
              <h2>Phone</h2>
              <p>Call us to get a direct quote to find a package that's right for you!</p>
              <a href='tel: 01158372772'>01158 372772</a>
            </div>

            {/* <div className={styles.OptionsItem}>
              <i>
                <FontAwesomeIcon icon={faComment} />
              </i>
              <h2>Live Chat</h2>
              <p>Go a question to ask? Try our live chat to get a quick answer!</p>
              <p>Click the <b>Chat Bubble (</b><span></span><b>)</b> in the bottom right!</p>
            </div> */}
          </div>

          <form className={styles.ContactForm} onSubmit={(e) => Submit(e)}>
            {User.ID ? <div className={styles.QuickFill}>
              <button title="Quickfill fields based on signed in account information" type="button" onClick={() => QuickFill()}>
                <span>Quickfill Information</span>
                <i>
                  <FontAwesomeIcon icon={faArrowRight} />
                </i>
              </button>
            </div> : <></>}

            <Input Required Placeholder="e.g. John" Type="text" Value={contact.Forename} Label="Forename" Icon={faUser} OnChange={(v) => setContact({ ...contact, Forename: v })} Width={"half"} />
            <Input Required Placeholder="e.g. Smith" Type="text" Value={contact.Surname} Label="Surname" Icon={faUser} OnChange={(v) => setContact({ ...contact, Surname: v })} Width={"half"} />
            <Input Required Placeholder="e.g. Example Ltd" Type="text" Value={contact.Company} Label="Company Name" Icon={faBuilding} OnChange={(v) => setContact({ ...contact, Company: v })} />
            <Input Required Placeholder="e.g. john.smith@example.com" Type="email" Value={contact.Email} Label="Email" Icon={faEnvelope} OnChange={(v) => setContact({ ...contact, Email: v })} Width={"half"} />
            <Input Required={false} Placeholder="e.g. +00 0123 456789" Type="text" Value={contact.Phone} Label="Phone Number" Icon={faPhone} OnChange={(v) => setContact({ ...contact, Phone: v })} Width={"half"} />
            <Input Required Placeholder="Write your message here..." Type="textarea" Value={contact.Message} Label="Message" Icon={undefined} OnChange={(v) => setContact({ ...contact, Message: v })} />

            <div className="capcha">
              <ReCAPTCHA
                onExpired={() => { setContactMessage("RECAPTCHA has expired, please try again"); setContactSuccess(false) }}
                onErrored={() => { setContactMessage("An Error has Occurred on the RECAPTCHA, please try again"); setContactSuccess(false) }}
                sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => {
                  setContact({ ...contact, Captcha: e ? e : "" });
                  setContactMessage("");
                }} />
            </div>

            {contactMessage ? <div className={`${styles.Message} ${contactSuccess ? styles.Success : styles.Error}`}>
              <p>{contactMessage}</p>
            </div> : <></>}

            <button type="submit" disabled={contact.Captcha === ""}>
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
