import { faAddressCard, faBuilding, faCheckCircle, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as styles from './About.module.scss';
import devicesImg from '../../../assets/IMG/PrimeHR-Devices.png';
import Articles from '../Articles/Articles';
import Blogs from '../Blog/Blogs';

const About: React.FC = () => {
  return (
    <>
      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>What PrimeHR Does</h1>
          <p>We provide online memberships which  allow you to read, edit and print your own copies of our easy-to-use HR documentation, ensuring you always have access to the most up to date templates. Along with our library of FREE resources, we also offer three levels of membership – Bronze, Silver and Gold – each of which contain a unique package of online resources to suit your HR needs.</p>

          <div className={styles.SectionImageInfo}>
            <div className={styles.SectionBlock}>
              <h1>What do our clients say about our Helpline?</h1>
              <p>
                “Advice just when I needed it”
                Jo rang about an employee who was in their probation and wasn’t performing, we discussed the role and what was needed, took time to understand the situation and then advised on the best course of action with a simple 4 stage plan.
              </p>
            </div>

            <div className={styles.SectionBlock}>
              <h1>What did Jo say a week later?</h1>
              <p>
                I couldn’t believe the difference in my team and how it felt to have a weight lifted off my shoulders.  Thanks to PrimeHR, I had the confidence and knowledge to do what I needed and then I simply downloaded the right documents and then I dealt with the employee.
              </p>
            </div>
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={devicesImg} />
          </div>

          <p>
            Often our clients don’t know where to turn and unlike other advice lines we understand what it is like to be a small business owner, worry about cash flow and fly by the seat of your pants, sometimes making hasty decisions you regret days later!
          </p>

          <p>
            You can avoid having sleepless nights by talking to us, subscribing to the helpline and getting sensible advice that helps you manage legal & business risk.
          </p>
        </div>
      </div>

      <Articles onAbout={true} />

      <Blogs onAbout={true} />

      <div className={styles.Icons}>
        <div className={styles.Icon}>
          <i>
            <FontAwesomeIcon icon={faCheckCircle} />
          </i>
          <p>Not Tied Down To Long Term Contracts, Cancel Anytime!</p>
        </div>

        <div className={styles.Icon}>
          <i>
            <FontAwesomeIcon icon={faFolderOpen} />
          </i>
          <p>Easy Access To Editable HR Documents That Suit Your Business Needs</p>
        </div>

        <div className={styles.Icon}>
          <i>
            <FontAwesomeIcon icon={faAddressCard} />
          </i>
          <p>Access To Qualified and Professional HR Practitioners! Available 24/7</p>
        </div>

        <div className={styles.Icon}>
          <i>
            <FontAwesomeIcon icon={faBuilding} />
          </i>
          <p>Flexible Packages That Will Support Your Business Growth</p>
        </div>
      </div>
    </>
  );
};

export default About;
