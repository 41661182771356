// extracted by mini-css-extract-plugin
export var Freebie = "About-module--Freebie--2c6e8";
export var FreebieImages = "About-module--FreebieImages--e4c58";
export var Freebies = "About-module--Freebies--d8cb4";
export var Icon = "About-module--Icon--4effb";
export var Icons = "About-module--Icons--ce5a4";
export var Section = "About-module--Section--8dbc4";
export var SectionBlock = "About-module--SectionBlock--38ce6";
export var SectionImage = "About-module--SectionImage--4d575";
export var SectionImageInfo = "About-module--SectionImageInfo--6b681";
export var SectionInner = "About-module--SectionInner--16fee";