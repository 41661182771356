import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import About from '../About/About';
import Contact from '../Contact/Contact';
import * as styles from './Home.module.scss';
import { Helmet } from 'react-helmet';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { Link, navigate } from 'gatsby';
import LogoImg from '../../../assets/IMG/Logo-New-Full.png';
import firstImg from '../../../assets/IMG/Banner/1.webp';
import secondImg from '../../../assets/IMG/Banner/2.webp';
import thirdImg from '../../../assets/IMG/Banner/3.webp';
import forthImg from '../../../assets/IMG/Banner/4.webp';
import fifthImg from '../../../assets/IMG/Banner/5.webp';
import Features from '../Features/Features';

const Home: React.FC = () => {
  const images: string[] = [firstImg, secondImg, thirdImg, forthImg, fifthImg];
  const features: string[] = ["Manage your employee's absences and holidays", "A Safe & Secure Story Solution for your employee's data", "A customisable solution to tracking your employee's work time", "Plan your employee's shifts for any style of working"];
  const [search, setSearch] = useState<string>("");
  const [image, setImage] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      if ((image + 1) === images.length)
        setImage(0)
      else
        setImage(image + 1)
    }, 7500);
  }, [image]);

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Helping Your Small Business Grow</title><meta charSet='utf-8' />
    </Helmet>

    <div className={styles.Banner}>
      {
        images.map((background, i) => {
          return <div className={`${styles.BannerImage} ${i === image ? styles.Show : ""}`} style={{ backgroundImage: `url('${background}')` }}></div>
        })
      }
      <div className={styles.BannerInner}>
        <div className={styles.BannerContent}>
          <div className={styles.BannerLogo}>
            <img loading={"lazy"} src={LogoImg} />
          </div>

          <p>
            <h1>Looking for your next HR Solution?</h1>

            PrimeHR is a low-cost option for the Small Business Owner who wants a simple to use HR system that covers all areas of the modern business environment.

            <b>Explore our useful features:</b>

            <ul>
              {
                features.map((feature: string) => {
                  return <li><i><FontAwesomeIcon icon={faCheckCircle} /></i>{feature}</li>
                })
              }
            </ul>
          </p>

          <Link to="/Packages">
            Check Out Our Packages
            <i>
              <FontAwesomeIcon icon={faArrowRight} />
            </i>
          </Link>
        </div>

        <div className={styles.Advert}>
          <div className={styles.AdvertInner}>
            <div className={styles.AdvertInfo}>
              <h1>Are you a HR Consultant?</h1>
              <p>If you are HR Consultant that needs some easy to use documents and some expert advice then you might be interested in our HR Consultant Package!</p>
              <b>This package comes with:</b>
              <ul>
                <li><i><FontAwesomeIcon icon={faCheckCircle} /></i>All of the Silver Documents</li>
                <li><i><FontAwesomeIcon icon={faCheckCircle} /></i>All of the Gold Documents</li>
                <li><i><FontAwesomeIcon icon={faCheckCircle} /></i>Our Expert HR Advice Line</li>
                <li><i><FontAwesomeIcon icon={faCheckCircle} /></i>All of our current tools and future features!</li>
              </ul>
              <Link to={"/Subscribe/Consultant"}>Try It Now For £1</Link>
            </div>

            <div className={styles.AdvertImage}>
              <div className={styles.AdvertImageCircle}>
                <h2>Only</h2>
                <h1>£1.00</h1>
                <h2>For your first month!</h2>
                <small>Then only £29.99 per month after your first month, cancel anytime!</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.Search}>
      <div className={styles.SearchInner}>
        <div className={styles.SearchInput}>
          <i><FontAwesomeIcon icon={faSearch} /></i>
          <input type="search" placeholder={"Search..."} value={search} onChange={(e) => { setSearch(e.target.value); navigate(`/Search?search=${encodeURIComponent(e.target.value)}`) }} />
        </div>
      </div>
    </div>

    <About />

    <Features />

    <Contact hideBanner={true} />
  </>);
};

export default Home;
