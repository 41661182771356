// extracted by mini-css-extract-plugin
export var Contact = "Contact-module--Contact--e99c7";
export var ContactForm = "Contact-module--ContactForm--8847d";
export var ContactInner = "Contact-module--ContactInner--e22a0";
export var Error = "Contact-module--Error--9cd82";
export var Message = "Contact-module--Message--396c8";
export var Options = "Contact-module--Options--3134e";
export var OptionsItem = "Contact-module--OptionsItem--dba0c";
export var QuickFill = "Contact-module--QuickFill--f5389";
export var Success = "Contact-module--Success--65785";
export var capcha = "Contact-module--capcha--86170";