import { Link } from 'gatsby';
import React from 'react';
import * as styles from './style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { BlogsList } from '../../../Data/Articles';
import { Article } from '../../../Classes/Article';

type props = {
  onAbout?: boolean;
}

const Blogs: React.FC<props> = ({ onAbout }) => {
  return (
    <>
      <div className={styles.Freebies}>
        <div className={styles.FreebiesInner}>
          <h1>Check Out Our Blog {onAbout ? <span><Link to={`/Blogs`}>View All <i><FontAwesomeIcon icon={faArrowRight} /></i></Link></span> : <></>}</h1>

          <div className={styles.Articles}>
            <div className={styles.ArticlesInner}>
              {
                BlogsList.filter((a, ind) => ind < 6).map((article: Article) => {
                  return <Link to={article.Route} className={styles.Freebie}>
                    <div className={styles.FreebieImages}>
                      <img loading={"lazy"} src={article.Image} />
                    </div>
                    <div className={styles.FreebieInner}>
                      <i>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </i>

                      <h2>{article.Title}</h2>
                      <h3>{article.SubTitle}</h3>
                      <p>{article.Description}</p>
                    </div>
                  </Link>
                })
              }
            </div>
          </div>

          {
            onAbout ? <></> : BlogsList.filter((a, ind) => ind >= 6).map((article: Article) => {
              return <Link to={article.Route} className={styles.Freebie}>
                <div className={styles.FreebieImages}>
                  <img loading={"lazy"} src={article.Image} />
                </div>
                <div className={styles.FreebieInner}>
                  <i>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </i>

                  <h2>{article.Title}</h2>
                  <h3>{article.SubTitle}</h3>
                  <p>{article.Description}</p>
                </div>
              </Link>
            })
          }
        </div>
      </div>
    </>
  );
};

export default Blogs;
