import React from 'react';
import Layout from './Layout/layout';
import Home from '../Views/Website/Home/Home';
import { Package } from '../Classes/Package';

export default function IndexRoute(props: any) {
  const Packages: Package[] = props.pageContext.Packages;

  return (<Layout>
    <Home packages={Packages} />
  </Layout>)
}