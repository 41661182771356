// extracted by mini-css-extract-plugin
export var Articles = "style-module--Articles--f0914";
export var ArticlesInner = "style-module--ArticlesInner--31e6c";
export var ArticlesTitle = "style-module--ArticlesTitle--bab85";
export var Freebie = "style-module--Freebie--678f3";
export var FreebieImages = "style-module--FreebieImages--34d64";
export var FreebieInner = "style-module--FreebieInner--5aef5";
export var Freebies = "style-module--Freebies--0b49c";
export var FreebiesInner = "style-module--FreebiesInner--a5c23";
export var FreebiesSide = "style-module--FreebiesSide--8c945";
export var Icon = "style-module--Icon--bfb56";
export var Icons = "style-module--Icons--68193";